var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    { staticClass: "iq-select", attrs: { label: _vm.label, prop: _vm.prop } },
    [
      _c("el-autocomplete", {
        directives: [{ name: "uppercase", rawName: "v-uppercase" }],
        staticClass: "iq-select",
        class: { fullWidth: _vm.size === "fullWidth" ? "fullWidth" : false },
        attrs: {
          disabled: _vm.disabled,
          placeholder: _vm.placeholder,
          "fetch-suggestions": _vm.options,
        },
        on: { select: _vm.onSelect, focus: _vm.onFocus },
        model: {
          value: _vm.inputVal,
          callback: function ($$v) {
            _vm.inputVal = $$v
          },
          expression: "inputVal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }